import ArticleSectionModule from '@Feature/ArticleSection';
import BannerModule from '@Feature/Banner';
import GenericFormModule from '@Feature/GenericForm';
import BeforeAfterSliderModule from '@Feature/BeforeAfterSlider';
import Carousel from '@Feature/Carousel';
import CollapseModule from '@Feature/Collapse';
import CaptchaModule from '@Feature/Captcha';
import DiagnosisTriggerModule from '@Feature/DiagnosisTrigger';
import FlipperModule from '@Feature/Flipper';
import JumpToModule from '@Feature/JumpTo';
import RadioSelection from '@Feature/RadioSelection';

import LoaderModule from '@Feature/Loader';
import LogoutModule from '@Feature/Logout';
import ProductsModule from '@Feature/LrpProducts';
import MainMenuModule from '@Feature/MainMenu';
import PanelModule from '@Feature/Panel';
import GeoPopinModule from '@Feature/GeoPopin';
import ProductBattle from '@Feature/ProductBattle';
import CountrySwitcherModule from '@Feature/CountrySwitcher';
// import ProgressBarModule from '@Feature/ProgressBar'; will use in the next sprint
import ResponsiveImageModule from '@Feature/ResponsiveImage';
import RoutineModule from '@Feature/Routine';
import SelectMultipleModule from '@Feature/SelectMultiple';
import SmileyModule from '@Feature/SmileyVote';
import SpriteBackgroundModule from '@Feature/SpriteBackground';
import StickyBarModule from '@Feature/StickyBar';
import SprinklrModule from '@Feature/Sprinklr';
import SliderModule from '@Feature/Slider';
import NoJsHandler from '@Feature/NoJsHandler';
import VideoPlayerModule from '@Feature/VideoPlayer';
import LoadMoreModule from '@Feature/LoadMore';
import CookieBannerModule from '@Feature/CookieBanner';
import PharmacyCtaButtonModule from '@Feature/PharmacyCtaButton';
import SpotscanEmbedModule from '@Feature/SpotscanEmbed';
import ArticleTagging from '@Feature/articleTagging';
import ArticleList from '@Feature/ArticleList';
import SustainabilityModule from '@Feature/Sustainability';
import GenericBannerYoutubePlayer from '@Feature/GenericBannerYoutubePlayer';
import QuestionAnsSchemaMarkup from '@Feature/QuestionAnsSchemaMarkup';
import HeaderBannerModule from '@Feature/HeaderBanner';
import TextBannerModule from '@Feature/TextBanner';
import ProductHeader from '@Feature/ProductHeader';
import LrpRetailer from '@Feature/LrpRetailer';
import NewsletterSubscription from '@/Feature/NewsletterSubscription';
import NewsletterSubscriptionV2 from '@/Feature/NewsletterSubscription-V2';
import SuccessModal from '@Feature/SuccessModal';
import BrandContentHub from '@Feature/BrandContentHub';
import VueScrollSnap from '@Feature/VueScrollSnap';
import Countdown from '@Feature/Countdown';
import GenericBanner from '@Feature/GenericBanner';
// import packShot from '@Feature/PackShot';
import MySkinSos from '@Feature/FEMySkinSOS';
import ImagesMosaic from '@Feature/ImagesMosaic';
import NewsletterModule from '@Feature/Newsletter';
import NewsletterSticky from '@Feature/NewsletterSticky';
import contrastSwitcherButton from '@Feature/contrastSwitcherButton';
import LanguageSwitcher from '@Feature/LanguageSwitcher';
// import GlossaryModule from '@Feature/Glossary';
import IngredientListModule from '@Feature/Glossary';
import simpleList from '@Feature/SimpleList';
import StarData from '@Feature/StarData';
import KeyIngredients from '@Feature/KeyIngredients';
import DataPush from '@Feature/DataPush';
import ArticlePushV2 from '@Feature/ArticlePush-V2';
import SustainabilityModuleV2 from '@Feature/Sustainability-V2';
import Accordions from '@Feature/Accordions';
import StickyBar from '@Feature/StickyBar-V2';
import BigQuote from '@Feature/BigQuote';
import BeforeAfterV2 from '@Feature/BeforeAfter-V2';
import StarReview from '@Feature/StarReview';
import RoutineV2 from '@Feature/Routine-V2';
import HowToApply from '@Feature/HowToApply';
import ProvenBenefits from '@Feature/ProvenBenefits';
import ProvenEfficacy from '@Feature/ProvenEfficacy';
import SafetyStandard from '@Feature/SafetyStandard';
import Faq from '@Feature/FAQ';
import BackToTop from '@Feature/BackToTop';
import Whatsapp from '@Feature/Whatsapp';
import AccessibilityModalWindow from '@Feature/AccessibilityModalWindow';
import RelatedManualArticle from '@Feature/RelatedManualArticle';
import ContentPush from '@Feature/ContentPush';
import productPopin from '@Feature/LrpProducts/productPopin';
import ArticleHighlightPush from '@Feature/ArticleHighlightPush';
import footerBanner from '@Feature/FooterBanner';
import safeSpace from '@Feature/safeSpace';
import HomePageTransparencySlider from '@Feature/HomePageTransparencySlider';
import articleHeader from '@Feature/Articleheader';
import articleProven from '@Feature/articleProven';
import MultiPopIn from '@Feature/MultiPopIn';
import SafeSpaceArticles from '@Feature/SafeSpaceArticles';
import HighlightedArticle from '@Feature/HighlightedArticle';
import SafeSpaceCarousel from '@Feature/SafeSpaceCarousel';

import { AnalyticsHandler, default as AnalyticsHandlerModule } from '@Foundation/analyticsHandler';
import { customFormAnalytics } from '@Foundation/directives/customFormAnalytics/customFormAnalytics';
import { clickoutside } from '@Foundation/directives/clickoutside/clickoutside';
import { clickextension } from '@Foundation/directives/clickExtension/clickextension';
import { equalheight } from '@Foundation/directives/equalHeight/equalheight';
import { preventBodyScroll } from '@Foundation/directives/preventBodyScroll/preventBodyScroll';
import { background } from '@Foundation/directives/background/background';
import { ellipsis } from '@Foundation/directives/ellipsis/ellipsis';
import { imageloaded } from '@Foundation/directives/imageloaded/imageloaded';
import { srcset } from '@Foundation/directives/srcset/srcset';
import { sticky } from '@Foundation/directives/sticky/sticky';
import { setposition } from '@Foundation/directives/setposition/setposition';
import { debug } from '@Foundation/env/env';
import { InputHandler } from '@Foundation/inputHandler';
import LoadScriptModule from '@Foundation/loadscript';
import MqHandler from '@Foundation/mqHandler/MqHandler';
import { KeySiteCore } from '@Foundation/sitecore';
import { VueModule } from '@Foundation/utilities';
import '@Foundation/wsf/wsf';
// Project
import '@Themes/index.scss';
import Vue from 'vue';
import VueStarRating from 'vue-star-rating';
import starRatingWrapper from '../../../../../fractal/components/Atoms/star-rating/star-rating-wrapper.vue';
import vueCookie from 'vue-cookie';
import loadZoneCustomizations from '@loreal/zone-plugin-loader';

import './registerServiceWorker';
import { store } from './store';

import './storelocator.config';
Vue.use(KeySiteCore);
Vue.use(vueCookie);
Vue.component('star-rating', VueStarRating);
Vue.component('star-rating-wrapper', starRatingWrapper);
// Configure MqHandler
MqHandler.nomockgetMqHandler({
  small: 768,
  medium: 1024,
});
// Configure AnalyticsHandler
AnalyticsHandler.getAnalyticsHandler(debug);

// Configure InputHandler
InputHandler.getInputHandler();

// Configuration ignored amp-* component
Vue.config.ignoredElements = [
  'amp-story',
  'amp-story-page',
  'amp-img',
  'amp-story-grid-layer',
  'amp-video',
  'amp-story-bookend',
];

// Configuration ignored amp-* component
Vue.config.keyCodes = {
  home: 36,
  end: 35,
};
/**
 * Initialize any zone-level customizations.
 * Every zone/country can create their own Vue components and make them available
 * to their code through the use of a Vue.js Plugin
 *
 * @see https://vuejs.org/v2/guide/plugins.html#Writing-a-Plugin
 * */

loadZoneCustomizations(Vue, store);

if (screen.width <= 600 && document.getElementsByClassName('fb_customer_chat_icon')[0]) {
  document.getElementsByClassName('fb_customer_chat_icon')[0].style.bottom = '65px !important';
}
window.RefAppMctb && Vue.use(window.RefAppMctb);
window.RefAppRatingsAndReview && Vue.use(window.RefAppRatingsAndReview);
window.RefAppIframe && Vue.use(window.RefAppIframe);

export default VueModule({
  asyncImports: [
    // {
    //   module: () => import(/* webpackChunkName: "rfp-ratings-reviews" */ '@Feature/RatingsReviews'),
    //   components: [
    //     'rfpRatingsTitleSection',
    //     'rfpRatingsWriteReview',
    //     'rfpRatingsRatingSnapshot',
    //     'rfpRatingsWriteReviewSuccess',
    //     'rfpRatingsWriteReviewWarning',
    //     'rfpRatingsAverageCustomerRatings',
    //     'rfpRatingsMostHelpfulFavorableReview',
    //     'rfpRatingsMostHelpfulCriticalReview',
    //     'rfpRatingsShowFullReview',
    //     'rfpRatingsList',
    //     'rfpRatingsHelpful',
    //     'rfpRatingsStarRatingProduct',
    //     'rfpRatingsAlreadySubmited',
    //     'rfpRatingsLoadingOverlay',
    //     'rfpCarousel',
    //     'rfpSlide',
    //     'rfpRatingsPaginationLinks',
    //     'rfpRatingsTags',
    //     'rfpLrpRatingsStarRatingProduct',
    //     'rfpRatingsStar',
    //     'star',
    //     'rfpRatingsStarRatingProduct'
    //   ],
    // },
    // {
    //   module: async () => import(/* webpackChunkName: "rfp-iframe" */ '@Feature/IFrame'),
    //   components: ['rfpIframeMainContainer', 'rfpIframeLoader', 'rfpIframeError'],
    // },
    {
      module: () => import(/* webpackChunkName: "modal" */ '@Feature/Modal'),
      components: ['Modal'],
    },
    {
      module: () => import(/* webpackChunkName: "youtube-player" */ '@Feature/YoutubePlayer'),
      components: ['YoutubePlayer'],
    },
    // {
    //   module: () => import(/* webpackChunkName: "popin-story" */ '@Feature/PopinStory'),
    //   components: [
    //     'PopinStory'
    //   ]
    // },
    // {
    //   module: () => import(/* webpackChunkName: "fractal-devbar" */ '@Feature/FractalDevbar'),
    //   components: [
    //     'FractalDevbar'
    //   ]
    // },
    {
      module: () => import(/* webpackChunkName: "pdp-components" */ '@Feature/LrpPDPcomponents'),
      components: [
        'ProductViewer',
        'ColorVariant',
        'VolumeVariant',
        'buynow',
        'addtocart',
        'ProductViewerV2',
        'ProductDescription',
        'ColorVariantV2',
        'VolumeVariantV2',
        'ScrollRating',
      ],
    },
    {
      module: () => import(/* webpackChunkName: "add-to-homescreen" */ '@Feature/Sampler'),
      components: ['Sampler'],
    },
    {
      module: () => import(/* webpackChunkName: "add-to-homescreen" */ '@Feature/Notification'),
      components: ['Notification'],
    },
    {
      module: () => import(/* webpackChunkName: "custom-forms" */ '@Feature/CustomForms'),
      components: [
        'DateOfBirth',
        'FieldSelect',
        'FieldRadio',
        'FieldTextarea',
        'FieldInput',
        'FieldHelp',
        'FieldCheckbox',
        'CustomFormNewsLetter',
        'CustomFormContact',
        'CustomFormRegister',
        'CustomFormLogin',
        'CustomFormEditPassword',
        'CustomFormForgotPassword1',
        'CustomFormForgotPassword2',
        'CustomFormPersonalInfo',
        'FormItem',
        'FormGroup',
      ],
    },
  ],
  imports: [
    // Foundation
    AnalyticsHandlerModule,
    LoadScriptModule,
    // Feature
    ArticleSectionModule,
    ResponsiveImageModule,
    BannerModule,
    RadioSelection,
    HeaderBannerModule,
    TextBannerModule,
    ProductHeader,
    NewsletterSubscription,
    SuccessModal,
    GeoPopinModule,
    DiagnosisTriggerModule,
    FlipperModule,
    MainMenuModule,
    Carousel,
    GenericFormModule,
    VideoPlayerModule,
    SmileyModule,
    CollapseModule,
    CaptchaModule,
    SpriteBackgroundModule,
    RoutineModule,
    PanelModule,
    SelectMultipleModule,
    ProductsModule,
    LoaderModule,
    JumpToModule,
    LogoutModule,
    RoutineModule,
    StickyBarModule,
    SliderModule,
    LoadMoreModule,
    NewsletterModule,
    NewsletterSticky,
    BeforeAfterSliderModule,
    ProductBattle,
    CountrySwitcherModule,
    // packShot,
    MySkinSos,
    ImagesMosaic,
    contrastSwitcherButton,
    IngredientListModule,
    // ProgressBarModule, will use in the next sprint
    NoJsHandler,
    SprinklrModule,
    CookieBannerModule,
    PharmacyCtaButtonModule,
    SpotscanEmbedModule,
    simpleList,
    ArticleTagging,
    ArticleList,
    SustainabilityModule,
    GenericBannerYoutubePlayer,
    QuestionAnsSchemaMarkup,
    LrpRetailer,
    BrandContentHub,
    VueScrollSnap,
    Countdown,
    GenericBanner,
    StarData,
    DataPush,
    ArticlePushV2,
    SustainabilityModuleV2,
    Accordions,
    StickyBar,
    BigQuote,
    BeforeAfterV2,
    StarReview,
    RoutineV2,
    ProvenBenefits,
    HowToApply,
    ProvenEfficacy,
    KeyIngredients,
    SafetyStandard,
    Faq,
    BackToTop,
    Whatsapp,
    AccessibilityModalWindow,
    NewsletterSubscriptionV2,
    LanguageSwitcher,
    ContentPush,
    productPopin,
    ArticleHighlightPush,
    footerBanner,
    safeSpace,
    HomePageTransparencySlider,
    articleHeader,
    articleProven,
    MultiPopIn,
    SafeSpaceArticles,
    HighlightedArticle,
    SafeSpaceCarousel,
    // FieldCheckbox
  ],
  directives: {
    background,
    clickoutside,
    clickextension,
    equalheight,
    srcset,
    imageloaded,
    ellipsis,
    sticky,
    setposition,
    customFormAnalytics,
    preventBodyScroll,
    RelatedManualArticle,
  },
  methods: {
    openAccessibility() {
      document.getElementById('accessibility-popup').classList.add('show');
      window.eventBus.$emit('accessibility-click', true);
    },
  },
  filters: {},
  store,
});
